import React from "react";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
} from "react-pro-sidebar";
import Social from "../../social/Social";

const menuContent = [
  {
    name: "Menus",
    defaultOpen:true,
    dropDownItems: [
      {
        name: "Home",
        routerPath: "/",
      },
      {
        name: "Portfolio",
        routerPath: "/portfolio",
      },
      {
        name: "About Us",
        routerPath: "/about-us",
      },
      {
        name: "Blog",
        routerPath: "/blog",
      },
      {
        name: "Contact",
        routerPath: "/contact",
      },
    ],
  },
  // {
  //   name: "Portfolio",
  //   routerPath: "/portfolio",
  //   additionalStyle: {
  //     display: "none",
  //   },
  //   dropDownItems: [
  //     // {
  //     //   name: "Works Grid",
  //     //   routerPath: "/works-grid",
  //     // },
  //     // {
  //     //   name: "Works Masonry",
  //     //   routerPath: "/works-masonry",
  //     // },
  //     // {
  //     //   name: "Works Listing",
  //     //   routerPath: "/works-listing",
  //     // },
  //     // {
  //     //   name: "Works Carousel",
  //     //   routerPath: "/works-carousel",
  //     // },
  //     // {
  //     //   name: "Works Showcase",
  //     //   routerPath: "/works-showcase",
  //     // },
  //   ],
  // },
  // {
  //   name: "Blog",
  //   additionalStyle: {
  //   },
  //   dropDownItems: [
  //     {
  //       name: "Blog Grid",
  //       routerPath: "/blog-grid",
  //     },
  //     {
  //       name: "Blog Masonry",
  //       routerPath: "/blog-masonry",
  //     },
  //     {
  //       name: "Blog Sidebar",
  //       routerPath: "/blog-sidebar",
  //     },
  //     {
  //       name: "Blog Details",
  //       routerPath: "/blog-details",
  //     },
  //     {
  //       name: "Blog Details Sidebar",
  //       routerPath: "/blog-details-sidebar",
  //     },
  //   ],
  // },
  // {
  //   name: "Pages",
  //   additionalStyle: {
  //   },
  //   dropDownItems: [
  //     {
  //       name: "About Us",
  //       routerPath: "/about-us",
  //     },
  //     {
  //       name: "About Me",
  //       routerPath: "/about-me",
  //     },
  //     {
  //       name: "Services",
  //       routerPath: "/service",
  //     },
  //     {
  //       name: "Service Details",
  //       routerPath: "/service-details",
  //     },
  //     {
  //       name: "Pricing",
  //       routerPath: "/pricing",
  //     },
  //     {
  //       name: "Team",
  //       routerPath: "/team",
  //     },
  //     {
  //       name: "FAQ",
  //       routerPath: "/faq",
  //     },
  //     {
  //       name: "Contact",
  //       routerPath: "/contact",
  //     },
  //     {
  //       name: "Page 404",
  //       routerPath: "/404",
  //     },
  //     {
  //       name: "Coming Soon",
  //       routerPath: "/coming-soon",
  //     },
  //   ],
  // },
];

const MobileMenu = () => {
  return (
    <>
      <div className="ptf-offcanvas-menu__navigation">
        <ProSidebar>
          <SidebarContent>
            <Menu className="sidebar-menu_wrapper">
              {menuContent.map((item, i) => (
                <SubMenu title={item.name} key={i} defaultOpen={item.defaultOpen}>
                  {item.dropDownItems.map((val, i) => (
                    <MenuItem key={i}>
                      {/*<Link to={val.routerPath}>{val.name}</Link>*/}
                      <Link to={location => ({ ...location, pathname:val.routerPath })}>{val.name}</Link>
                    </MenuItem>
                  ))}
                </SubMenu>
              ))}
            </Menu>
          </SidebarContent>
        </ProSidebar>
      </div>
      {/* End .ptf-offcanvas-menu__navigation */}

      <div className="ptf-offcanvas-menu__footer">
        <p className="ptf-offcanvas-menu__copyright">
          ©{new Date().getFullYear()}{" "}
          <span>
            {" "}
            PT Mostar Studio Indonesia
          </span>
          . All Rights Reserved.
        </p>
        <Social />
      </div>
      {/* End .ptf-offcanvas-menu__footer */}
    </>
  );
};

export default MobileMenu;
