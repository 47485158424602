import React from "react";
import { Link } from "react-router-dom";


//todo: cari cara untuk baca url. Baca string after / yang pertama. setelah itu baru lu cari yang string nya = lowercase(name). tambahin class "wo-columns current-menu-item"
const pathname = window.location.pathname;
const thePath = '/'+pathname.split('/')[1];
// console.log(thePath);

const menuContent = [
  {
    name: "Home",
    // activeClass: "sf-with-ul",
    activeClass: "",
    // menuClass: "two-columns current-menu-item",
    menuClass: "",
    routerPath: "/",
    additionalStyle: {
      display: "none",
    },
    dropDownItems: [
      // {
      //   name: "Home Default",
      //   routerPath: "/home-default",
      // },
      // {
      //   name: "Home Studio",
      //   routerPath: "/home-studio",
      // },
      // {
      //   name: "Home Agency",
      //   routerPath: "/home-agency",
      // },
      // {
      //   name: "Home Minimal",
      //   routerPath: "/home-minimal",
      // },
      // {
      //   name: "Home Dark",
      //   routerPath: "/home-dark",
      // },
      // {
      //   name: "Home Freelancer",
      //   routerPath: "/home-freelancer",
      // },
      // {
      //   name: "Home Trending",
      //   routerPath: "/home-trending",
      // },
      // {
      //   name: "Home Modern",
      //   routerPath: "/home-modern",
      // },
    ],
  },
  {
    name: "Portfolio",
    activeClass: "sf-with-ul",
    menuClass: "",
    routerPath: "https://blog.mostar.co.id/portfolio-list",
    additionalStyle: {
      display: "none",
    },
    dropDownItems: [
    ],
  },
  {
    name: "About Us",
    activeClass: "",
    menuClass: "",
    routerPath: "/about-us",
    additionalStyle:{
      display: "none",
    },
    dropDownItems: [
    ],
  },
  {
    name: "Blog",
    activeClass: "",
    menuClass: "",
    routerPath: "https://blog.mostar.co.id/blog",
    additionalStyle:{
      display:"none"
    },
    dropDownItems: [
      {
        name: "Blog Grid",
        routerPath: "/blog-grid",
      },
      {
        name: "Blog Masonry",
        routerPath: "/blog-masonry",
      },
      {
        name: "Blog Sidebar",
        routerPath: "/blog-sidebar",
      },
      {
        name: "Blog Details",
        routerPath: "/blog-details",
      },
      {
        name: "Blog Details Sidebar",
        routerPath: "/blog-details-sidebar",
      },
    ],
  },
  {
    name: "Contact",
    activeClass: "",
    menuClass: "two-columns",
    routerPath: "/contact",
    additionalStyle:{
      display: "none",
    },
    dropDownItems: [
    ],
  },
];


const DropdownMenu = () => {
  return (
    <ul className="sf-menu">
      {menuContent.map((item, i) => (
        <li className={`menu-item-ha-childrens ${ thePath  ==  item.routerPath ? 'two-columns current-menu-item' : '' }`} key={i}>
          {/* <a href={item.routerPath} className={item.activeClass}> */}
          <a href={item.routerPath} className={thePath === item.routerPath ? 'sf-with-ul' : '' }> 
            <span>{item.name}</span>
          </a>

          <ul className="sub-menu mega" style={item.additionalStyle}>
            {item.dropDownItems.map((val, i) => (
              <li key={i}>
                <Link to={val.routerPath}>
                  {" "}
                  <span>{val.name}</span>
                </Link>
                
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
};

export default DropdownMenu;
